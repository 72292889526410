import React from 'react'
import { Helmet } from 'react-helmet'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMeetup } from '@fortawesome/free-brands-svg-icons'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import SidebarCommunity from '../../components/SidebarCommunity'

const BlogPage = () => (
  <Layout>
    <Helmet>
      <body className="community blog" />
    </Helmet>

    <SEO title="Developers" keywords={['developer tools', 'developers', 'sdk']}
         description="The tools you need to build Proof of Process apps." />

    <SidebarCommunity />
    
    <main className="content">
      <h2>Blog posts</h2>
      <p>Find technical blog posts about the Stratumn tech stack.</p>

      <div class="posts">
        <div class="blogpost">
          <img src="https://stratumn.com/assets/smart-contracts-sml-2e8ad1c1269f4149b120114c606cc5a6e5e8b82fdc84f820411de304723a0310.jpg" />
          <span>03.07.2018</span>
          <a href="https://stratumn.com/thinking/smart-contracts-the-nakamoto-way/"><h3>Smart Contracts — Fulfilling Nakamoto's Dreams</h3> </a>
          <p>Contracts are essentially about strengthening human relationships in business and beyond...</p>
        </div>

        <div class="blogpost">
          <img src="https://stratumn.com/assets/zkp-location-sml-f579136470bfcc70f67300501c0edb6978d7b570b2cc3edcb37d79b97c9dea99.jpg" />
          <span>03.07.2018</span>
          <a href="https://stratumn.com/thinking/zero-knowledge-proof-of-location/"><h3>Zero-Knowledge Proof of Location</h3> </a>
          <p>At Stratumn, we care deeply about our users’ data. We strongly believe that people should be in control of their data...</p>
        </div>

        <div class="blogpost">
          <img src="https://stratumn.com/assets/in-proofs-part-2-sml-79a7767f209a4b44de574c46dec70d34ba7371ddc0bbee5d207c0a1a2b5c4144.jpg" />
          <span>24.01.2018</span>
          <a href="https://stratumn.com/thinking/in-proofs-we-trust-part-two/"><h3>In Proofs, We Trust — Part 2</h3> </a>
          <p>As long as the millions of untrusting bitcoiners are able to prove their bitcoin transactions to each other....</p>
        </div>

        <div class="blogpost">
          <img src="https://stratumn.com/assets/analyzing-performance-sml-658c2d3b6ebbb865a687b791c4514e0553e261a559bb16ff559bdecc1ba5f5e0.jpg" />
          <span>23.01.2018</span>
          <a href="https://stratumn.com/thinking/analyzing-performance-in-blockchain-based-systems/"><h3>Analyzing Performance in Blockchain-Based Systems</h3></a>
          <p>Blockchain technologies offer some of the slowest...</p>
        </div>
      </div>
    </main>
  </Layout>
)

export default BlogPage
